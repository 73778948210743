.addBook_outer_container {
  background-color: #242a46 !important;
  color: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Login Screen */

.phoneCountryAB {
  margin: 0.1rem 0;
  border-radius: 10px;
  border: #242a46 1px solid;

  .PhoneInput {
    background-color: white;
    border-radius: 10px;

    .PhoneInputCountry {
      margin: 0 1rem;
      background-color: white;
    }

    input {
      border: none;
      margin: 0;
      //   padding: 0 !important;
      outline: none;
    }
  }

  @media screen and (max-width: 798px) {
    .PhoneInputCountry {
      margin: 0 !important;
      margin-right: 1rem !important;
    }
  }
}

.addBook_inner_container {
  height: 100%;
  width: 25%;
  // margin-top: 5rem !important;
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;

  .inputField {
    margin-top: 1rem;

    p {
      font-size: 15px;
    }

    // .phoneCountry {
    //   .PhoneInput {
    //     input {
    //       padding: 0 !important;
    //     }
    //   }
    // }
  }

  .uploadImage {
    img {
      border-style: dashed;
      width: 100%;
    }
  }

  .inputField_input {
    // margin: 8px 0;
    width: 100%;
    border-radius: 10px;
    color: black !important;
    border: none !important;
    outline: none;
    color: black;
    border: 1px solid white;
    border-radius: 10px;
    align-items: center;
    // padding: 12px 20px 12px 20px;
    box-sizing: border-box;
    background-color: white;

    p {
      cursor: pointer;
    }
  }

  .password_Icon {
    font-size: 25px;
    color: #8990af;
    cursor: pointer;
  }

  .remember {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .submitContent {
    text-align: center;
  }

  .tAndC {
    display: flex;
    font-size: small;
    gap: 4px;
    align-items: center;

    input {
      width: 24px;
    }
  }

  .heading {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 32px;

    @media screen and (max-width: 798px) {
      font-size: 25px;
    }

    h4 {
      font-weight: 700;
    }

    img {
      width: 52px;
    }
  }

  .uploadImage {
    margin: 1rem 0;
    width: 100px;
    height: 100px;
    border: 1px solid #8990af;
    border-radius: 10px;
    border-style: dashed;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    p {
      font-size: x-small;
    }
  }

  .uploadIcon {
    font-size: x-large;
    color: #8990af;
  }

  .info {
    display: flex;
    gap: 8px;
    font-size: 13px;
    align-items: center;
  }

  .block1 {
    margin-top: 1rem;
  }

  .button_group1 {
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: nowrap;
    // width: 100%;
    // margin: 1rem 0;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(6, 1fr);

    button {
      /* margin-right: 0.5rem; */
      padding: 10px 0px 10px 0px;
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      border: 1px solid white;
      border-radius: 5px;
      background-color: transparent;
      color: black;
    }
  }

  .button_group2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;

    button {
      /* margin-right: 0.5rem; */
      width: 30%;
      padding: 10px 0;
      font-size: x-small;
      border: 1px solid white;
      border-radius: 5px;
      background-color: white;
      color: #242a46;
    }
  }

  .fpButtons {
    align-items: center;
    display: flex;
    gap: 1rem;

    .next_button {
      width: 100%;

      button {
        width: 100%;
        border: none;
        background-color: #6fa5ef;
        color: white;
        padding: 1rem;
        outline: none;
        border-radius: 10px;
      }
    }
  }

  .backArrow {
    border: 1px solid #6fa5ef;
    border-radius: 10px;
    // width: 2.5rem;
    padding: 1rem;
    color: #6fa5ef;
    // height: 2.5rem;
  }

  .paywith {
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;

    button {
      width: 100%;
      display: flex;
      gap: 10px;
      font-size: large;
      text-align: center;
      justify-content: center;
      border: none;
      outline: none;
      align-items: center;
      border: 1px solid white;
      border-radius: 10px;
    }
  }
}

// .timePickers {
//   .MuiFormControl-root {
//     // border: 1px solid white !important;
//   }
// }


@media screen and (max-width: 798px) {
  .addBook_outer_container {
    min-height: 100vh;
    height: max-content;
    padding: 2rem 0;
  }

  .addBook_inner_container {
    width: 80%;
    margin: 4rem auto;
    justify-content: center;
  }

  .button_group2 button {
    padding: 10px 10px;
  }

  .info {
    gap: 0% !important;

    p {
      font-size: x-small !important;
    }
  }

  .addBook_inner_container .button_group1 {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(3, 1fr);

    button {
      margin: 8px 0;
    }
  }

  .addBook_inner_container .button_group1 button {
    width: 100%;
  }
}

.css-15v22id-MuiAccordionDetails-root {
  background-color: #242a46;
  border: none;
  padding: 0;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: white;
}