.adminInstructors_list_row1 {
  display: flex;
  justify-content: space-between;
  min-height: max-content;

  button {
    width: max-content;
    margin: 0;
  }
}

.instructor_info_1 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: black !important;

  img {
    width: 50px;
    height: 50px;
  }

  .license_name {
    display: flex;
    flex-direction: column;
  }
}

.contact_info {
  display: flex;
  flex-direction: column;
}
