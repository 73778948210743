.membership_container {
    background: #242a46;
  
    .header_membership {
      display: flex;
      justify-content: center;
    }
  
    .membership_content {
      min-height: calc(84vh - 50px);
      display: flex;
      align-items: center;
      justify-content: center;
      height: max-content;
      overflow: auto;
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
  
  .pricing_table {
    display: flex;
    justify-content: center;
    gap: 30px;
    width: 95%;
  
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 20px;
  
      .price--table--item.highlight {
        transform: none !important;
      }
  
      .price--table--item {
        p.price--plan,
        ul.lists--plan > .list--plan--items {
          font-size: 14px !important;
        }
      }
    }
  
    .price--table--item {
      background: #fff;
      padding: clamp(30px, 3vw, 40px) clamp(18px, 2vw, 25px);
      border-radius: 15px;
  
      &.highlight {
        background: #6fa5ef;
        transform: scale(1.1);

        .heading--price, .price--plan{
            color: #fff;
        }
      }
  
      h2.heading--price {
        text-align: center;
        font-size: clamp(25px, 2vw, 30px);
  
        + p.price--plan {
          text-align: center;
          font-size: 16px;
          margin-top: 10px;
  
          > span.price--amount {
            font-size: clamp(30px, 3vw, 44px);
            font-weight: 700;
          }
        }
  
        &.highlight {
          color: #fff;
        }
      }
  
      ul.lists--plan {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
  
        > .list--plan--items {
          font-size: 16px;
          display: flex;
          align-items: center;
          gap: 8px;
  
          &.white-highlight {
            color: #fff;
          }
        }
      }
  
      button.button {
        font-weight: 700;
        font-size: 15px;
  
        &.highlight--btn {
          background: #fff;
          color: #6fa5ef;
        }
      }
      button.button.disabled--btn {
        background: #ccc;
        cursor: no-drop;
    }
    }
  }
  