.newBenefits {
  margin: 4rem 0;

  .newBenefits_row1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 2rem;

    .uploadFile {
      position: relative;
      overflow: hidden;
      width: 100%;

      .customFileInput {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        background-color: #6fa5ef;
        color: #fff;
        cursor: pointer;
        border: none;
        border-radius: 5px;
      }

      .inputField1 {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
      }
    }

    .inputField {
      margin-top: 1rem;
      padding: 10px;
      border: 2px solid #d9d9d9;
      width: 100%;
      box-sizing: border-box;
      border-radius: 5px;
    }
  }

  .newBenefits_row2 {
    width: 100%;
    display: flex;
    margin-top: 1rem;

    .description {
      width: 100%;

      textarea {
        width: 100%;
        margin: 1rem 0;
        padding: 10px;
        box-sizing: border-box;
        border: 2px solid #d9d9d9;
        border-radius: 5px;
      }
    }
  }
}
