.admin_outer_container {
  display: flex;
  width: 100%;
  min-height: 95vh;
}

.adminDashboard_row1 {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  .leftside {
    display: flex;
    gap: 2rem;
    align-items: baseline;
  }

  .rightSide {
    gap: 1rem;
    display: flex;

    .ant-select-selector {
      height: 3rem;
      text-align: center;
      align-items: center;
      width: 9rem;
    }
  }
}

.adminDashboard_row2 {
  display: flex;
  gap: 1rem;
  margin: 2rem 0;

  .blocks {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    gap: 1rem;
    color: black !important;
    justify-content: center;
    justify-content: space-between;

    .block {
      background-color: #e2eaf1;
      padding: 1rem;
      color: black !important;
      box-sizing: border-box;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .rightBlock {
    width: 25%;
    background-color: #e2eaf1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;

    .upperSection {
      width: 100%;
      margin: auto 0;
      text-align: center;
    }

    .lowerSection {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}

.reportOption {
  width: auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #6fa5ef;
}

.reportTable {
  border-collapse: separate;
  border-spacing: 0 2rem;

  tbody {
    .table-row {
      border: 5px solid black;
    }
  }

  tr {
    margin: 0 !important;

    th {
      margin-right: 0.5rem;
      text-align: start;
    }
  }
}

.calender_modal_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.newLesson_row1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 1rem 0;

  .newLesson_row1_block1 {
    width: 50% !important;
  }

  .newLesson_row1_block2 {
    width: 50%;
  }
}

.newLesson_row2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 1rem 0;

  .newLesson_row2_block1 {
    width: 50%;

    input {
      width: 100%;
      padding: 0.5rem;
      box-sizing: border-box;
      border-radius: 10px;
      margin: 0.5rem 0;
      border: 1px #8990af solid;
    }
  }

  .newLesson_row2_block2 {
    width: 50%;

    input {
      width: 100%;
      padding: 0.5rem;
      box-sizing: border-box;
      border-radius: 10px;
      margin: 0.5rem 0;
      border: 1px #8990af solid;
    }
  }
}

.adminCredits {
  width: 40%;
  display: flex;
  flex-direction: column;
  // background-color: #e2eaf1;
  border: 1px #d9d9d9 solid;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 1rem;
  box-sizing: border-box;

  .adminChargesTable_drivingSchoolProfile {
    border-collapse: separate;
    border-spacing: 0 1rem;
    width: 100%;
    text-align: center;

    th {
      white-space: nowrap;
      max-width: 200px;
    }

    tbody {
      tr {
        td {
          white-space: nowrap;
          box-sizing: border-box;
          padding: 0 10px;
          input {
            padding: 0 10px;
            width: max-content;
            text-align: center;
          }
        }
      }
    }
  }
}

.newLesson_row3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 1rem 0;

  .newLesson_row3_block1 {
    width: 50%;

    input {
      width: 100%;
      padding: 0.5rem;
      box-sizing: border-box;
      border-radius: 10px;
      margin: 0.5rem 0;
      border: 1px #8990af solid;
    }
  }

  .newLesson_row3_block2 {
    width: 50%;

    input {
      width: 100%;
      padding: 0.5rem;
      box-sizing: border-box;
      border-radius: 10px;
      margin: 0.5rem 0;
      border: 1px #8990af solid;
    }
  }
}

.newLesson_row4 {
  textarea {
    margin: 0.5rem 0;
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
    border: 1px #8990af solid;
    border-radius: 10px;
  }
}

.adminChargesTable {
  width: 40%;
  text-align: center;
  margin: 3rem 0 !important;

  tbody {
    tr {
      td {
        padding: 10px;

        input {
          text-align: center;
          padding: 0.5rem !important;
        }
      }
    }
  }
}

.admin_Notification {
  width: 100%;
  margin: 2rem;
  overflow: hidden;
}

.notificationAdmin {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-content: center;
  gap: 1rem;
  margin: 3rem 0;

  .notification_block {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
  }

  button {
    margin-bottom: 0 !important;
    padding: 0.5rem !important;
  }
}

.notification_block .row1 {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
}

.notificationData {
  padding: 10px;
}

.searchGrid {
  display: flex;
  align-items: center;
  width: 70%;
  height: 0.4rem;
  border: 1px #8990af solid;
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 10px;

  input {
    width: 100%;
    margin: 0 0.5rem;
    border: none;
    font-size: large;
  }
}

.phoneField {
  margin: 0.1rem 0;
  border-radius: 10px;
  border: #8990af 1px solid;

  .PhoneInput {
    background-color: white;
    border-radius: 10px;

    .PhoneInputCountry {
      margin: 0 1rem;
      background-color: white;
    }

    input {
      border: none;
      margin: 0;
      //   padding: 0 !important;
      outline: none;
    }
  }

  @media screen and (max-width: 798px) {
    .PhoneInputCountry {
      margin: 0 !important;
      margin-right: 1rem !important;
    }
  }
}
