.signup_outer_container {
    background-color: #242a46 !important;
    color: white;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}



/* Login Screen */

.signup_inner_container {
    height: 100%;
    width: 30%;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .inputField {
        margin-top: 1rem;

        p {
            font-size: 15px;
        }

        .phoneCountry {
            .PhoneInput {
                input {
                    padding: 0 !important;
                }
            }
        }
    }

    .uploadImage {
        img {
            border-style: dashed;
            width: 100%;
        }
    }


    .days_lesson {
        display: grid;
        justify-content: space-between;
        gap: 5px;
        grid-template-columns: repeat(7, 1fr);

        @media screen and (max-width: 798px) {
            display: grid;
            gap: 5px;
            grid-template-columns: repeat(3, 1fr);
        }

        button {
            width: 100%;
        }
    }

    .timePickers {
        display: flex;
        // padding: 1rem 0;
        gap: 10px;
        align-items: center;

        input {
            background-color: white;
            color: black;
            border: 1px solid white;
            border-radius: 10px;
            width: 100%;
            padding: .5rem 10px;
            outline: none;

            @media screen and (max-width: 798px) {

                width: 100%;
            }
        }
    }


    .inputField_input {
        margin: 8px 0;
        width: 100%;
        border-radius: 10px;
        color: black !important;
        border: none;
        outline: none;
        color: black;
        border: 1px solid white;
        border-radius: 10px;
        align-items: center;
        padding: 12px 20px 12px 20px;
        box-sizing: border-box;
        background-color: white;

        p {
            cursor: pointer;
        }

    }


    .password_Icon {
        font-size: 25px;
        color: #8990AF;
        cursor: pointer;
    }

    .remember {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .submitContent {
        text-align: center;
    }

    .tAndC {
        display: flex;
        font-size: small;
        gap: 4px;
        align-items: center;

        input {
            width: 24px;
        }
    }

    .heading {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 32px;

        @media screen and (max-width: 798px) {
            font-size: 25px;
        }

        h4 {
            font-weight: 700;
        }

        img {
            width: 52px;
        }
    }


    .uploadImage {
        margin: 1rem 0;
        width: 100px;
        height: 100px;
        border: 1px solid #8990AF;
        border-radius: 10px;
        border-style: dashed;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;

        p {
            font-size: x-small;
        }
    }


    .uploadIcon {
        font-size: x-large;
        color: #8990AF;
    }

    .info {
        display: flex;
        gap: 8px;
        font-size: 13px;
        align-items: center;
    }

    .block1 {
        margin-top: 1rem;

    }

    .button_group1 {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(6, 1fr);

        button {
            padding: 10px 0px 10px 0px;
            width: 100%;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid white;
            border-radius: 5px;
            background-color: transparent;
            color: black;
        }
    }

    .button_group2 {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 1rem;
        width: 100%;

        button {
            width: 100%;
            padding: 10px 0;
            font-size: x-small;
            border: 1px solid white;
            border-radius: 5px;
            background-color: white;
            color: #242a46;
        }
    }

    .fpButtons {
        align-items: center;
        display: flex;
        gap: 1rem;

        .next_button {
            width: 100%;

            button {
                width: 100%;
                border: none;
                background-color: #6FA5EF;
                color: white;
                padding: 1rem;
                outline: none;
                border-radius: 10px;
            }
        }
    }

    .backArrow {
        border: 1px solid #6FA5EF;
        border-radius: 10px;
        padding: 1rem;
        color: #6FA5EF;
    }

    .paywith {
        display: flex;
        flex-wrap: nowrap;
        gap: 1rem;

        button {
            width: 100%;
            display: flex;
            gap: 10px;
            font-size: large;
            text-align: center;
            justify-content: center;
            border: none;
            outline: none;
            align-items: center;
            border: 1px solid white;
            border-radius: 10px;
        }
    }
}

@media screen and (max-width: 798px) {

    .signup_outer_container {
        min-height: 100vh;
        height: max-content;
        padding: 2rem 0,
    }

    .signup_inner_container {
        width: 80%;
        margin: 0 auto;
        justify-content: center;
    }

    .button_group2 button {
        padding: 10px 10px;
    }

    .info {
        gap: 0% !important;

        p {
            font-size: x-small !important;
        }
    }

    .signup_inner_container .button_group1 {
        display: grid;
        gap: 5px;
        grid-template-columns: repeat(3, 1fr);

        button {
            margin: 8px 0
        }
    }

    .signup_inner_container .button_group1 button {
        width: 100%;
    }
}

.css-15v22id-MuiAccordionDetails-root {
    background-color: #242a46;
    border: none;
    padding: 0;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    color: white;
}