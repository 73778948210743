.instructorProfile {
  display: flex;
  width: 100%;
  gap: 2rem;
  margin: 2rem;

  .leftSide {
    width: 50%;

    .instructor_info {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #e2eaf1;
      border-radius: 5px;
      padding: 1rem;
      box-sizing: border-box;

      .row1 {
        display: flex;
        width: 100%;
        gap: 0.5rem;
        margin-bottom: 1rem;
        align-items: center;

        .instructor_content {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 0.5rem;

          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .buttons {
              display: flex;
              gap: 1rem;

              button {
                width: max-content;
                outline: none;
                margin: 0 !important;
                padding: 0.7rem !important;
                border-radius: 10px;
                border: none;
                cursor: pointer;
              }
            }
          }
        }
      }

      .moreInformation {
        width: 100%;
        padding: 0.5rem;
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;
      }
    }
    .instructorCredits {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #e2eaf1;
      border-radius: 5px;
      padding: 1rem;
      margin-top: 1rem;
      box-sizing: border-box;

      .adminChargesTable_instructorProfile {
        width: 100%;
        text-align: center;
        // margin: 3rem 0 !important;

        th {
          width: 33%;
        }

        tbody {
          tr {
            padding-top: 1rem;
            td {
              input {
                text-align: center;
                // padding: 0.5rem !important;
              }
            }
          }
        }
      }
    }
  }

  .rightSide {
    width: 50%;

    .rightSide_row1 {
      width: 100%;
      background-color: #e2eaf1;
      border-radius: 5px;
      padding: 1rem;
      box-sizing: border-box;

      .row_1 {
        margin-top: 1rem;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .rightSide {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .rightSide_row2 {
      .blocks {
        margin-top: 1rem;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        gap: 1rem;
        justify-content: center;
        justify-content: space-between;

        .block {
          background-color: #e2eaf1;
          padding: 1rem;
          box-sizing: border-box;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          p {
            font-size: 12px;
            color: #8990af;
          }
        }
      }
    }

    .rightSide_row3 {
      margin-top: 1rem;

      .row1 {
        display: flex;
        width: 100%;
        align-items: center;
      }

      .row2 {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
      }

      .carInfo {
        background-color: #e2eaf1;
        padding: 1rem;
        width: 30%;
        margin-top: 1rem;
        box-sizing: border-box;
        border-radius: 5px;

        img {
          width: 100%;
          aspect-ratio: 1;
        }

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.admin_instructor_edit_input {
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 2px solid #d9d9d9;
}

.lessonBlockInst {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: 2px solid #d9d9d9;
  box-sizing: border-box;
  margin-bottom: 1rem;

  .row1 {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .row2 {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.studentProfile {
  width: 100%;
  overflow: hidden;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  margin: 2rem;

  .leftSide {
    width: 100%;

    .instructor_info {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #e2eaf1;
      border-radius: 5px;
      padding: 1rem;
      box-sizing: border-box;

      .row1 {
        display: flex;
        width: 100%;
        gap: 0.5rem;
        margin-bottom: 1rem;
        align-items: center;

        img {
          width: 120px !important;
          height: 120px !important;
          border-radius: 10px;
        }

        .instructor_content {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 0.5rem;

          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .buttons {
              display: flex;
              gap: 1rem;

              button {
                width: max-content;
                outline: none;
                margin: 0 !important;
                padding: 0.7rem !important;
                border-radius: 10px;
                border: none;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.userInputFieldClient {
  margin-top: 1rem;
  width: max-content;

  input {
    margin: 0.5rem 0;
    padding: 1rem 1rem;
    border-radius: 5px;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
    box-sizing: border-box;
    outline: none !important;
    background-color: transparent;

    @media screen and (max-width: 798px) {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .paymentInfo {
    margin: 1rem 0;
    padding: 0 1rem;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    width: 40%;
    box-sizing: border-box;
    outline: none;
    display: flex;
    align-items: center;

    @media screen and (max-width: 798px) {
      width: 100%;
    }
  }
}

.addCar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 1rem;
  // margin: 3rem 0;

  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 798px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .notification_block {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    width: 27rem;
  }

  button {
    margin-bottom: 0 !important;
    padding: 0.5rem !important;
  }
}

.addCarField {
  margin-top: 1rem;

  input {
    margin: 0.5rem 0;
    padding: 1rem 1rem;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    background-color: transparent;

    @media screen and (max-width: 798px) {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .paymentInfo {
    margin: 1rem 0;
    padding: 0 1rem;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    width: 40%;
    box-sizing: border-box;
    outline: none;
    display: flex;
    align-items: center;

    @media screen and (max-width: 798px) {
      width: 100%;
    }
  }
}

/* HIDE RADIO */
.hideRadio {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
/* IMAGE STYLES */
[type="radio"] + img {
  cursor: pointer;
  width: 5rem;
}
/* CHECKED STYLES */
[type="radio"]:checked + img {
  outline: 2px solid #242a46;
}

.inputCredit {
  border: none;
  outline: none;
  border: 0.5px solid grey;
  border-radius: 5px;
  height: 1.5rem;
  width: 50%;
  text-align: center;
}
