.login_outer_container {
  background-color: #242a46;
  color: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_outer_container::-webkit-scrollbar {
  display: none;
}

.login_outer_container-scrollbar-thumb {
  background-color: transparent;
}

.bookdrive_Logo {
  position: fixed;
  top: 0%;
  left: 0%;
  padding: 2rem;
  width: 15rem;
}

/* Login Screen */

.login_inner_container::-webkit-scrollbar {
  display: none;
}

.login_inner_container {
  height: 100%;
  width: 30%;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  .goToDashboardButton {
    width: 30%;
  }

  .inputField {
    margin-top: 1.5rem;

    p {
      font-size: small;
    }

    .inputField_Password {
      display: flex;
      justify-content: space-between;

      .fPassword {
        color: #6fa5ef;
      }
    }
  }

  .inputField_input {
    margin: 10px 0;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      cursor: pointer;
    }

    input {
      border: none;
      outline: none;
      color: black;
      border: 1px solid white;
      border-radius: 10px;
      align-items: center;
      width: 100%;
      padding: 5px;
      background-color: white;
    }
  }

  .password_Icon {
    font-size: 25px;
    color: #8990af;
    cursor: pointer;
  }

  .remember {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .submitContent {
    text-align: center;

    button:hover {
      background-color: #6fa5ef;
      color: white;
    }

    .signUpButton {
      color: #6fa5ef !important;
    }
  }

  /* Forgot Password */

  .forgotPassword_container {
    width: 30%;
    margin: 2rem auto;
    justify-content: center;
    padding: 10px;
  }

  .fpButtons {
    align-items: center;
    display: flex;
    gap: 1rem;
  }

  .backArrow {
    border: 1px solid #6fa5ef;
    border-radius: 10px;
    width: 2.5rem;
    padding: 5px;
    color: #6fa5ef;
    height: 2.5rem;
  }

  /* Signup page */

  .tAndC {
    display: flex;
    align-items: center;
    font-size: small;
    gap: 1rem;

    p {
      a {
        text-decoration: underline;
        color: #6fa5ef;
      }
    }
  }
}

.clientNavLink.active {
  background-color: #242a46 !important;
}

.fp_page {
  width: 80%;

  @media screen and (max-width: 798px) {
    width: 85%;
    overflow-x: hidden;
  }

  .fp_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;

    @media screen and (max-width: 798px) {
      // flex-direction: column;
      // margin-top: 2rem;
    }

    .loginButton {
      background-color: #6fa5ef;
      // height: 3rem !important;
      // width: 5rem !important;
      padding: 1rem 2rem;
      box-sizing: border-box;
      width: max-content;
      border-radius: 10px;
      font-size: large;

      @media screen and (max-width: 798px) {
        padding: 1rem;
        font-size: small;
      }
    }

    img {
      width: 12rem;

      @media screen and (max-width: 798px) {
        width: 50%;
      }
    }
  }

  .fp_row1 {
    display: flex;
    margin-bottom: 2rem;
    // max-height: 80vh !important;
    // overflow-y: hidden;

    @media screen and (max-width: "798px") {
      flex-direction: column;
    }

    .leftSection {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2rem;

      @media screen and (max-width: 798px) {
        width: 100%;
      }

      h1 {
        font-size: 60px;
        // font-family: Brownstd, sans-serif;

        @media screen and (max-width: 798px) {
          font-size: 32px;
        }
      }

      h5 {
        font-size: 24px;
        line-height: 2.5rem;

        @media screen and (max-width: 798px) {
          font-size: 14px;
          line-height: 1.5rem;
        }
      }

      .visitor_info {
        width: 50%;
        display: flex;
        gap: 1rem;
        flex-direction: column;

        @media screen and (max-width: 798px) {
          width: 100%;
        }
        .visitor_info_role {
          border-radius: 10px;
          background-color: white;
          color: black;
          width: 100%;
          padding: 1rem;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          gap: 1rem;
          font-size: medium;

          @media screen and (max-width: 798px) {
            font-size: small;
          }

          h3 {
            white-space: nowrap;
          }

          select {
            border: none !important;
            outline: none !important;
            font-size: medium;
            width: 100%;
            background-color: white;
          }

          .visitorSelect {
            border: none !important;
            outline: none !important;
            font-size: medium;
            width: 100%;
            background-color: white;
          }

          input {
            border: none;
            outline: none;
            font-size: medium;
            width: 100%;
          }
        }
        .visitor_info_email {
          border-radius: 10px;
          background-color: white;
          color: black;
          width: 100%;
          box-sizing: border-box;
          padding: 1rem;
          display: flex;
          align-items: center;
          gap: 1rem;
          font-size: medium;

          input {
            border: none;
            outline: none;
            font-size: medium;
            width: 100%;
          }
        }
        .submitButton {
          font-size: medium;
          padding: 0.5rem 1rem;
          width: 40% !important;
        }
      }
    }

    .rightSection {
      width: 50%;
      text-align: center;

      @media screen and (max-width: 798px) {
        width: 100%;
      }

      .phone_image {
        width: 70%;
        // margin: 0 auto;
        // padding: 2rem;
        box-sizing: border-box;
      }
      .fp_connect {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2rem;

        .downloadOptions {
          display: flex;
          gap: 2rem;
          width: 100%;
          justify-content: center;

          .downloadImage {
            width: 30%;
          }
        }
      }
    }
  }
}

.fp_row2 {
  min-height: 100vh;
  display: flex;
  background-color: white;
  width: 90%;
  margin: auto;
  align-items: center;
  // justify-content: space-between;

  .leftSection {
    width: 60%;

    h1 {
      font-size: 56px;
      line-height: 1.5;
      margin-bottom: 20px !important;

      @media screen and (max-width: 798px) {
        font-size: x-large;
      }
    }

    p {
      font-size: 20px;
      color: #737273;
      line-height: 1.5;

      @media screen and (max-width: 798px) {
        font-size: medium;
      }
    }

    @media screen and (max-width: 798px) {
      width: 100%;

      margin: 2rem;
    }
  }

  .rightSection {
    text-align: center;

    img {
      width: 60%;
    }
  }

  @media screen and (max-width: 798px) {
    flex-direction: column;
  }
}

.fp_row3 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 90%;
  margin: auto;
  align-items: center;
  justify-content: center;
  gap: 5rem;

  @media screen and (max-width: 798px) {
    gap: 2rem;
  }

  h1 {
    margin: 0 auto;
    font-size: 56px;

    @media screen and (max-width: 798px) {
      font-size: x-large;
      text-align: center;
      margin-top: 3rem;
    }
  }

  .performance {
    display: flex;
    justify-content: space-between;
    gap: 3rem;

    @media screen and (max-width: 798px) {
      flex-direction: column;
      gap: 1rem;
    }

    .performanceBlock {
      width: 30%;
      text-align: center;
      padding: 2rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      @media screen and (max-width: 798px) {
        width: 100%;
        gap: 1rem;
      }

      h1 {
        font-size: 30px;
        margin-top: 0;
      }

      img {
        width: 5rem;
        margin: 0 auto;
      }

      p {
        color: #737273;
        font-size: 20px;
        // margin: 2rem 0;
        line-height: 1.5;
        text-align: left;
      }
    }
  }
}

.fp_row4 {
  min-height: 100vh;
  display: flex;
  background-color: white;
  width: 90%;
  margin: auto;
  gap: 5rem;
  align-items: center;

  @media screen and (max-width: 798px) {
    flex-direction: column;
  }

  .leftSection {
    width: 40%;
    text-align: center;

    @media screen and (max-width: 798px) {
      width: 100%;
    }

    img {
      width: 60%;
    }
  }

  .rightSection {
    width: 60%;

    padding-right: 10rem;

    h1 {
      font-size: 56px;
      line-height: 1.5;
      margin-bottom: 20px !important;

      @media screen and (max-width: 798px) {
        font-size: x-large;
      }
    }

    p {
      font-size: 20px;
      color: #737273;
      line-height: 1.5;

      @media screen and (max-width: 798px) {
        font-size: medium;
      }
    }

    @media screen and (max-width: 798px) {
      width: 100%;
      margin: 2rem;
      padding-right: 0;
    }
  }
}

.fp_row5 {
  min-height: 100vh;
  display: flex;
  background-color: white;
  width: 90%;
  margin: auto;
  align-items: center;
  // justify-content: space-between;

  .leftSection {
    width: 60%;

    h1 {
      font-size: 56px;
      line-height: 1.5;
      margin-bottom: 20px !important;

      @media screen and (max-width: 798px) {
        font-size: x-large;
      }
    }

    p {
      font-size: 20px;
      color: #737273;
      line-height: 1.5;

      @media screen and (max-width: 798px) {
        font-size: medium;
      }
    }

    @media screen and (max-width: 798px) {
      width: 100%;
      margin: 2rem;
    }
  }

  .rightSection {
    width: 40%;
    text-align: right;

    @media screen and (max-width: 798px) {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 798px) {
    flex-direction: column;
  }
}

.fp_footer {
  background-color: #242a46;

  .footer_row1 {
    min-height: max-content;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    // flex-direction: column;
    // align-items: center;

    @media screen and (max-width: 798px) {
      flex-direction: column;
    }

    .leftSection {
      padding: 3rem 0;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      img {
        width: 12rem;
        height: max-content !important;
      }

      h2 {
        color: white;
      }
    }

    .rightSection {
      padding: 3rem 0;
      color: white;
      display: flex;
      gap: 2rem;

      @media screen and (max-width: 798px) {
        padding: 0;
        margin: 0 auto;
        margin-bottom: 2rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 600px) {
  .login_inner_container {
    width: 70%;
    margin: 0 auto;
    display: flex !important;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .emailVerifyContent {
    font-size: medium;
  }

  .goToDashboardButton {
    width: max-content !important;
  }

  .bookdrive_Logo {
    width: 10rem;
    left: 50%;
    transform: translate(-50%);
  }

  .upcomingActivityBlock {
    flex-direction: column;
    gap: 1rem;
    text-align: left;
  }

  .footer {
    p {
      font-size: x-small;
    }
  }
}
