@font-face {
  font-family: "BookDriveFont";
  src: url("/src/assets/AvenirNextLTPro-Regular.otf") format("openType");
}

@font-face {
  font-family: "BookDriveFontBold";
  src: url("/src/assets/AvenirNextLTProBold.otf") format("openType");
}

* {
  font-family: "BookDriveFont";
  margin: 0;
  padding: 0;
}

.boldText {
  font-family: "BookDriveFontBold" !important;
}

.gray {
  color: #8990af;
}

.Toastify__toast button {
  width: min-content !important;
  padding: 0 !important;
}

button {
  margin: 1rem 0;
  width: 100%;
  border: none;
  background-color: #6fa5ef;
  color: white;
  padding: 1rem;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
}

/* Add this to your CSS file or use a CSS-in-JS solution */

.pagination-container {
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  max-width: 100%; /* Set a maximum width to contain the content */
}

.pagination {
  list-style: none;
  padding: 0;
  background-color: white !important;
  display: inline-flex;
  margin: 0;
  width: max-content !important;
}

.pagination .active-page {
  background-color: #242a46;
  color: white;
}

.pagination li {
  margin: 0 5px;
}

.pagination button {
  border-radius: 5px !important;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 5px 10px;
}

.container {
  width: 80%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0% !important;
}

a {
  text-decoration: none;
  width: 100%;
}

.menuitem {
  color: black !important;
}

ul {
  margin: 0;
}

/* li {
  list-style-type: none;
} */

/* ::-webkit-scrollbar {
  display: none;
} */

.nav-item {
  list-style: none !important;
}
.nav-link {
  color: white;
}

.nav-link.active {
  color: #6fa5ef !important;
  border-bottom: 1px solid #6fa5ef;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.neomorphic-btn {
  -webkit-border-radius: 50px;
  border-radius: 50px;
  background: #6fa5ef;
  -webkit-box-shadow: 5px 5px 21px #5f8ece, -5px -5px 21px #7fbcff;
  box-shadow: 5px 5px 21px #5f8ece, -5px -5px 21px #7fbcff;
}

.footer {
  text-align: center;
  width: 100%;
  padding: 0.5rem 0;
  bottom: 0;
  align-items: center;
  z-index: 99;
}

.react-multi-carousel-item {
  width: max-content !important;
}

.modal {
  position: absolute;
  top: 50%;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: auto !important;
  min-width: 30% !important;
  border-radius: 15px;
  padding: 1rem;
  max-height: 90%;
  overflow-y: scroll;
  font-size: 14px !important;
  outline: none;
}

@media screen and (max-width: 798px) {
  .modal {
    width: 80% !important;
  }
}

::-webkit-scrollbar {
  display: none;
}

.modal2 {
  position: absolute;
  top: 50%;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 30% !important;
  border-radius: 15px;
  padding: 1rem;
  max-height: 90%;
  overflow-y: scroll;
  font-size: 14px !important;
  outline: none;
}

@media screen and (max-width: 798px) {
  .modal2 {
    width: 80% !important;
  }
}

.tableHeading {
  white-space: nowrap !important;
  font-weight: bolder !important;
}

.ant-select-show-search:where(
    .css-dev-only-do-not-override-txh9fw
  ).ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  height: 100% !important;
}

:where(.css-txh9fw).ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-select-selector {
  width: auto !important;
}

.modal button {
  padding: 10px 2rem;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  margin: 0;
}

.columnName2 {
  white-space: "nowrap" !important;
  overflow: "hidden" !important;
  text-overflow: "ellipsis" !important;
}

#modal-modal-title {
  font-weight: 700;
}

.css-1odpjxs-MuiTabs-root {
  min-height: 0 !important;
}

.css-rcjyty-MuiTypography-root {
  margin-top: 1rem !important;
}

small {
  color: #8990af;
}

b {
  font-weight: bolder !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-left: 0 !important;
  padding-right: 5px !important;
}
