.nav {
  width: 100vw;
  position: fixed;
  top: 0%;
  background-color: #242a46;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px !important;

  .logo {
    padding: 10px 20px;

    img {
      width: 10rem;
    }
  }

  .navbar-items {
    display: flex;
  }

  .nav-item {
    margin: 0 1rem;
  }

  .left_nav {
    display: flex;
    align-items: center;

    .logo {
      img {
        width: 10rem !important;
      }
    }
  }

  .right_navbar {
    display: flex !important;
    flex-direction: row !important;
  }

  @media screen and (max-width: 798px) {
    display: none;
  }
}

.navbarNotification {
  width: 400px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  white-space: normal !important;
  padding-right: 0 !important;

  &:hover {
    background-color: white !important;
  }

  @media screen and (max-width: 798px) {
    width: auto !important;
  }

  .notificationLi {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;

    .notificationContent {
      width: 100%;
    }
  }

  .MuiAccordionSummary-content {
    align-items: center !important;
    display: flex !important;
    justify-content: space-between !important;
    gap: 20px !important;
  }
}

.navbarNotification1 {
  width: 400px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  white-space: normal !important;
  // padding-right: 0 !important;

  &:hover {
    background-color: white !important;
  }

  @media screen and (max-width: 798px) {
    width: auto !important;
  }

  .notificationLi {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;

    .notificationContent {
      width: 100%;
    }
  }

  .MuiAccordionSummary-content {
    align-items: center !important;
    display: flex !important;
    justify-content: space-between !important;
    gap: 20px !important;
  }
}

.notificationHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  width: 375px;
}

.badge {
  position: absolute;
  top: -8px;
  right: -5px;
  background-color: red;
  color: white;
  padding: 4px 8px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;

  @media screen and (max-width: 798px) {
    right: 0;
  }
}

.notification-badge {
  position: relative;
  display: inline-block;
}

.css-j8mwh6-MuiTabs-root {
  width: auto !important;
}

.nav_responsive {
  display: none;

  @media screen and (max-width: 798px) {
    display: flex;
    width: 100%;
    position: fixed;
    top: 0%;
    background-color: #242a46;
    z-index: 99;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .logo {
      padding: 1.5rem;

      img {
        width: 8rem !important;
      }
    }

    .hamburger {
      padding: 1.5rem;
      color: white;
    }

    .dropdown {
      display: flex;
      align-items: center;
    }
  }
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  width: 100% !important;
}
