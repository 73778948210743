.adduser_row1 {
    display: flex;
    margin: 1rem 0;
    gap: 1rem;
    overflow: hidden;

    .left {

        width: 60%;


        .email-input {
            margin: 1rem 0;
            display: flex;
            padding: .5rem 1rem;
            border: 1px solid grey;
            border-radius: 5px;
            width: 100%;
            flex-wrap: wrap;


            .token {
                background-color: #6fa5ef;
                margin: .5rem;
                display: flex;
                border-radius: 20px;
                align-items: center;

                p {
                    padding: 0 5px;
                }

                .remove-icon {
                    cursor: pointer;
                    color: grey;
                    padding: 0 5px;
                    font-size: x-large;
                }
            }

            input {
                padding: .5rem;
                border: none;
                width: 100%;
                outline: none;
            }
        }

    }

    .right {
        width: 40%;

        img {
            width: 100%;
        }
    }
}