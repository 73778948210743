.success_container {
    background: #242a46;

    .header_success {
        display: flex;
        justify-content: center;
    }

    .success_content {
        min-height: calc(84vh - 95px);
        display: flex;
        align-items: center;
        justify-content: center;
        height: max-content;
        overflow: auto;
        padding-top: 50px;
        padding-bottom: 50px;

        h1 {
            font-size: clamp(25px, 3vw, 35px);
            color: #fff;
            text-align: center;
        }

        p {
            color: #fff;
            text-align: center;
            font-size: clamp(18px, 2vw, 20px);
            margin-top: 10px;
        }
        svg{
            color: #fff;
            font-size: clamp(50px, 4vw, 70px);
        }
        .button{
            font-size: 15px;
            margin-top: 25px !important;
            margin: 1rem 0;
            width: 100%;
            border: none;
            background-color: #6fa5ef;
            color: white;
            padding: 1rem;
            outline: none;
            border-radius: 10px;
            cursor: pointer;
            display: flex        ;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
        }

        .box--div{
            text-align: center;
            // background: #6fa5ef;
            // padding: 50px 20px;
            // border-radius: 15px;
            // max-width: 500px;
            // width: 100%;

            img{
                max-width: 135px;
                width: 100%;
            }
        }
    }
}