.dropdown-item {
  &:hover {
    background-color: #d9d9d9 !important;
  }
}

small {
  font-size: small;
}

.clientdashboard_inner_container {
  width: 85%;
  min-height: 80vh;
  margin-top: 5rem;
  margin-bottom: 0%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 798px) {
    width: 100%;
    padding: 1rem;
    height: 100%;
    min-height: 86vh;
    box-sizing: border-box;
  }

  .dashboard_tabs {
    ul {
      width: max-content;
      background-color: #e2eaf1;
      border-radius: 10px;

      .nav_link.active {
        background-color: red !important;
      }
    }

    a {
      color: black;
      padding: 5px 40px;
    }

    @media screen and (max-width: 798px) {
      ul {
        width: 100%;

        li {
          width: 33%;

          a {
            text-align: center;
            padding: 5px 10px;
          }
        }
      }
    }
  }

  .findInstructor {
    display: flex;

    .findInstructor_filter {
      width: 25%;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media screen and (max-width: 798px) {
        display: none;
      }

      .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .inner_heading {
        display: flex;
        justify-content: space-between;
      }

      .filterRadio {
        align-items: center;
        display: flex;
        gap: 5px;
        margin-top: 1rem;

        input {
          height: 15px;
          width: 15px;
          margin-left: 10px;
        }

        label {
          font-size: 14px;
        }
      }

      h4 {
        font-weight: bolder !important;
        margin-top: 1rem;
      }
    }

    .instructorList {
      width: 75%;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media screen and (max-width: 798px) {
        width: 100%;
        padding: 0%;
      }

      .findInstructor_filter_responsive {
        display: none;

        @media screen and (max-width: 798px) {
          display: block;
        }
      }

      .instructorRow {
        border: 1px solid #d9d9d9;
        padding: 1rem;
        justify-content: space-between;
        align-items: center;
        display: flex;
        color: black;
        border-radius: 20px;
        margin: 1rem 0;

        @media screen and (max-width: 798px) {
          flex-direction: column;
        }

        tr {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 10px;

          @media screen and (max-width: 798px) {
            flex-direction: column;
            gap: 10px;
            align-items: center;
            justify-content: center;
          }

          td {
            @media screen and (max-width: 798px) {
              width: 100% !important;
              align-items: center;
              text-align: center;
              justify-content: center;
            }
          }
        }

        .instructorRow_info {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .connectButton {
          width: 20%;
          align-items: center;
          text-align: center;
          justify-content: center;

          @media screen and (max-width: 798px) {
            margin-top: 0.5rem;
            width: 100%;
          }

          button {
            width: 90%;
            border: none;
            border-radius: 10px;
            background-color: #242a46;
            color: white;
            padding: 0.5rem 1rem;
            font-size: 12px;

            @media screen and (max-width: 798px) {
              width: 70%;
            }
          }
        }
      }
    }
  }

  .instructor_profile {
    // display: flex;
    gap: 2rem;
    margin: 3rem 0;

    @media screen and (max-width: 798px) {
      flex-direction: column;
      margin: 0;
    }

    .first_row {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      gap: 2rem;

      @media screen and (max-width: 798px) {
        width: 100%;
        flex-direction: column;
      }

      .left_box1 {
        border: 1px solid #d9d9d9;
        border-radius: 20px;
        width: 75%;
        padding: 1rem;
        box-sizing: border-box;

        @media screen and (max-width: 798px) {
          width: 100%;
        }

        .left_box_row1 {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media screen and (max-width: 798px) {
            flex-direction: column;
            gap: 20px;
            align-items: center;
          }

          .profile {
            display: flex;
            align-items: center;

            @media screen and (max-width: 798px) {
              flex-direction: column;
              gap: 20px;
              text-align: center;
            }

            img {
              width: 70px;
              height: 70px;

              @media screen and (max-width: 798px) {
                margin-right: 0 !important;
              }
            }
          }
        }

        hr {
          // width: 90%;
          // margin: 0 auto;
          // margin: 1rem 0;
          margin-top: 1rem !important;
          margin-bottom: 1rem;
          height: 1px !important;
          border-radius: 30px;
          background: black;
          border: none !important;
          outline: none !important;
        }

        .left_box_row2 {
          // padding: 1rem 2rem;
          // display: flex;

          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: 1fr;
          justify-content: center;
          justify-content: space-between;

          @media screen and (max-width: 798px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
          }

          .instructor_info {
            display: flex;
            flex-direction: column;
            gap: 0 !important;

            p {
              font-weight: bold;
              margin-top: 10px;
              font-size: 15px;
            }
          }
        }
      }

      .right_box1 {
        padding: 1rem;
        box-sizing: border-box;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        // height: max-content;
        display: flex;
        width: 25%;
        flex-direction: column;
        background-color: #6fa5ef;
        color: white;
        text-align: center;

        h1 {
          padding: 1rem 0;
        }

        button {
          width: 100%;
          color: #6fa5ef;
          font-weight: bold;
          background-color: white;
        }

        @media screen and (max-width: 798px) {
          width: 100%;
          box-sizing: border-box;
        }
      }
    }

    .second_row {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      gap: 2rem;
      margin: 1rem 0;

      @media screen and (max-width: 798px) {
        flex-direction: column-reverse;
        box-sizing: border-box;
      }

      .left_Box2 {
        width: 75% !important;

        @media screen and (max-width: 798px) {
          width: 100% !important;
        }

        @media (min-width: 798px) and (max-width: 1024px) {
          width: 65% !important;
        }
      }

      .right_box2 {
        width: 25% !important;

        @media screen and (max-width: 798px) {
          width: 100% !important;
        }

        .secondBox_car {
          margin: 1rem 0;
          display: flex;
          padding: 1rem;
          gap: 1rem;
          border: 1px solid #d9d9d9;
          border-radius: 20px;

          img {
            height: 100%;
          }
        }
      }
    }
  }
}

.availableSlotsRow {
  margin: 1rem 0;
  display: flex;
  overflow-x: hidden;
  width: 100%;
  gap: 1rem;

  @media screen and (max-width: 798px) {
    flex-direction: column;
  }

  .dateItem {
    width: 100%;

    button {
      margin: 0 !important;
      padding: 1rem 0 !important;
      border-radius: 5px !important;
    }
  }
}

.containerAvailable {
  width: 100%;
  position: relative;
}

.arrows {
  // position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  padding: 10px 0;
  z-index: 1;
  box-sizing: border-box;
  margin: auto 0;

  button {
    background-color: white;
    border-radius: 10px;
    color: black;
    border: 1px solid gray;
    padding: 0.5rem;
    width: max-content;
    margin: 0;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: #242a46;
      color: white;
    }
  }
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0 !important;
}

.cancel_modal {
  @media screen and (max-width: 798px) {
    width: 300px !important;
  }
}

input[type="radio"] {
  accent-color: blue;
}

// .availableTimeSlots {
//     display: flex;
//     overflow-x: scroll;
//     box-sizing: border-box;
//     width: 790px;
//     width: 100%;

//     @media screen and (max-width: 798px) {
//         // flex-direction: column;
//         width: 100%;
//         align-items: center;
//     }
// }

.css-17o5nyn {
  justify-content: space-between !important;
}

.ant-drawer-header-title {
  flex-direction: row-reverse !important;
}

.ant-drawer-close {
  width: auto !important;
  padding: 0 !important;
  margin: 0 !important;
}

.ant-dropdown-menu-item {
  width: auto !important;
}

.changeProfilePicture {
  margin: 1rem 0;
  display: flex;
  gap: 5rem;

  @media screen and (max-width: 798px) {
    flex-direction: column;
    gap: 2rem;
  }

  .circle {
    width: 200px;
    height: 200px;
    // border-radius: 50%;
    position: relative;

    .image {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      position: absolute;
      top: 25px;
      left: 25px;
    }

    .camera-button {
      position: absolute;
      bottom: 10px;
      right: 10px;
      padding: 0.5rem;
      color: black;
      background-color: #d9d9d9;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .colorShades {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media screen and (max-width: 798px) {
      flex-direction: row;
    }

    button {
      width: 3rem;
      height: 3rem;
      border: none;
      border-radius: 50%;
      padding: 0%;
      margin: 0%;
    }
  }
}

.userProfile {
  .editProfile {
    width: 40%;
    margin-bottom: 3rem;

    @media screen and (max-width: 798px) {
      width: 100%;
    }
  }

  .userInputField {
    margin-top: 1rem;

    input {
      margin: 1rem 0;
      padding: 1rem 1rem;
      border-radius: 5px;
      border: 1px solid #d9d9d9;
      width: 40%;
      box-sizing: border-box;
      outline: none;

      @media screen and (max-width: 798px) {
        width: 100%;
        box-sizing: border-box;
      }
    }

    .paymentInfo {
      margin: 1rem 0;
      padding: 0 1rem;
      border-radius: 5px;
      border: 1px solid #d9d9d9;
      width: 40%;
      box-sizing: border-box;
      outline: none;
      display: flex;
      align-items: center;

      @media screen and (max-width: 798px) {
        width: 100%;
      }
    }

    .googleAddress {
      width: 42%;
      margin: 1rem 0;
      border-radius: 5px;
      border: #d9d9d9 1px solid;
    }

    .phoneCountry {
      width: 40%;

      @media screen and (max-width: 798px) {
        width: 100%;
      }
    }

    .days_lesson {
      width: 40%;
      display: grid;
      justify-content: space-between;
      gap: 5px;
      grid-template-columns: repeat(3, 1fr);

      @media screen and (max-width: 798px) {
        display: grid;
        gap: 5px;
        grid-template-columns: repeat(3, 1fr);
      }

      button {
        width: 100%;
        border: #8990af solid 1px;
      }
    }

    .timePickers {
      width: 40%;
      display: flex;
      // padding: 1rem 0;
      gap: 10px;
      align-items: center;

      input {
        background-color: white;
        color: black;
        border: 1px solid 8990AF;
        border-radius: 10px;
        width: 100%;
        padding: 0.5rem 10px;
        outline: none;

        @media screen and (max-width: 798px) {
          width: 100%;
        }
      }
    }
  }

  .changePassword {
    border: 1px solid #6fa5ef;
    border-radius: 10px;
    background-color: white;
    color: #6fa5ef;
    padding: 0.5rem 2rem;
  }
}

.mylessonsTabs {
  width: max-content !important;

  @media screen and (max-width: 798px) {
    width: 100% !important;
  }
}

.instructorsTabs {
  width: 100% !important;
  box-sizing: border-box;

  @media screen and (max-width: 798px) {
    width: 100% !important;
  }
}

.instructorNavLink {
  min-height: max-content !important;
  color: #0c101f;
  font-size: 14px !important;
  width: 25%;
  box-sizing: border-box;
  line-height: 20px;
  font-size: 12px !important;

  @media screen and (max-width: 798px) {
    width: 100%;
  }
}

.instructorNavLink.active {
  background-color: #242a46 !important;
  color: white !important;
  border-radius: 10px;
}

.clientNavLink {
  padding: 10px 43px !important;
  min-height: max-content !important;
  color: #0c101f;
  font-size: 12px !important;
  width: max-content;
  line-height: 20px;

  @media screen and (max-width: 798px) {
    width: 100%;
    font-size: 20px !important;
  }
}

.clientNavLink.active {
  background-color: #242a46 !important;
  color: white !important;
  border-radius: 10px;
  // padding: 1rem 2rem !important;
}

.css-1ujnqem-MuiTabs-root {
  // min-height: 0 !important;
  width: 100% !important;
  box-sizing: border-box !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  max-width: none !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  font-weight: 600 !important;
}

.responsiveTabs {
  display: none;

  @media screen and (max-width: 798px) {
    display: block;

    .ant-space {
      .ant-space-item {
        .ant-select {
          .ant-select-selector {
            height: 3rem !important;
            font-size: large;
            border-radius: 10px;
            align-items: center !important;
            background-color: #242a46 !important;
            color: white !important;
            text-align: center !important;
          }
        }
      }
    }

    .ant-select-arrow {
      color: white !important;
    }
  }
}

.mylessontabs_notResponsive {
  display: block;

  @media screen and (max-width: 798px) {
    display: none;
  }
}

.tabsMyLessons {
  background-color: #e2eaf1;
  border-radius: 12px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  min-height: 0 !important;
  box-sizing: border-box;

  @media screen and (max-width: 798px) {
    overflow-x: hidden !important;
  }
}

.phoneCountry {
  margin: 1rem 0;
  border-radius: 5px;
  border: #d9d9d9 1px solid;

  .PhoneInput {
    background-color: white;
    border-radius: 5px;

    .PhoneInputCountry {
      margin: 0 1rem;
      background-color: white;
    }

    input {
      border: none;
      margin: 0;
      // padding: 0 !important;
      outline: none;
    }
  }

  @media screen and (max-width: 798px) {
    .PhoneInputCountry {
      margin: 0 !important;
      margin-right: 1rem !important;
    }
  }
}

.css-19kzrtu {
  padding: 0 !important;
}

.swiper-slide {
  margin-right: 8px !important;
  width: fit-content;
}

p {
  font-size: 14px;
}

small {
  font-size: 12px;
}

.upcomingActivityBlock {
  // border-left: 15px solid !important;
  border-radius: 15px;
  border: 1px solid #d9d9d9;
  margin: 1rem 0;

  tr {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 0.7rem;

    @media screen and (max-width: 798px) {
      gap: 10px;
      text-align: center;
    }

    td {
      display: flex;

      .instructor_information {
        display: flex;
        gap: 10px;
        align-items: center;

        @media screen and (max-width: 798px) {
          flex-direction: column;
        }
      }

      @media screen and (max-width: 798px) {
        width: 100% !important;
        text-align: center;
        justify-content: center !important;
      }
    }

    @media screen and (max-width: 798px) {
      // gap: 1rem;
      flex-direction: column;
      // text-align: left;
    }
  }

  .cancelLecture {
    button {
      border: 2px solid #8990af;
      border-radius: 10px;
      background-color: white;
      color: #8990af;
      align-items: center;
      margin: 0% !important;
      padding: 0.5rem 1rem;
    }
  }

  @media screen and (max-width: 798px) {
    flex-direction: column;
    gap: 1rem;
    text-align: left;
  }
}

.completedActivityBlock {
  border-radius: 15px;
  margin: 1rem 0;
  border: 1px solid #d9d9d9;

  @media screen and (max-width: 798px) {
    flex-direction: column;
    gap: 1rem;
    text-align: left;
  }

  tr {
    display: flex;
    width: 100%;
    padding: 0.7rem;
    box-sizing: border-box;

    @media screen and (max-width: 798px) {
      gap: 10px;
      text-align: center;
    }

    td {
      display: flex;

      .instructor_information {
        display: flex;
        gap: 5px;
        align-items: center;

        @media screen and (max-width: 798px) {
          flex-direction: column;
        }
      }

      .lectureResult {
        display: flex;
        align-items: center;
        gap: 10px;

        @media screen and (max-width: 798px) {
          flex-direction: column;
        }
      }

      @media screen and (max-width: 798px) {
        width: 100% !important;
        text-align: center;
        justify-content: center !important;
      }
    }

    @media screen and (max-width: 798px) {
      // gap: 1rem;
      flex-direction: column;
      // text-align: left;
    }
  }

  .cancelLecture {
    button {
      width: 170px;
    }
  }
}

.cancelledActivityBlock {
  border-radius: 15px;
  margin: 1rem 0;
  border: 1px solid #d9d9d9;

  @media screen and (max-width: 798px) {
    flex-direction: column;
    gap: 1rem;
    text-align: left;
  }

  tr {
    display: flex;
    width: 100%;
    padding: 0.7rem;
    box-sizing: border-box;

    @media screen and (max-width: 798px) {
      gap: 10px;
      text-align: center;
    }

    td {
      display: flex;

      .instructor_information {
        display: flex;
        gap: 5px;
        align-items: center;

        @media screen and (max-width: 798px) {
          flex-direction: column;
        }
      }

      .lectureResult {
        display: flex;
        align-items: center;
        gap: 10px;

        @media screen and (max-width: 798px) {
          flex-direction: column;
        }
      }

      @media screen and (max-width: 798px) {
        width: 100% !important;
        text-align: center;
        justify-content: center !important;
      }
    }

    @media screen and (max-width: 798px) {
      // gap: 1rem;
      flex-direction: column;
      // text-align: left;
    }
  }

  .cancelLecture {
    display: flex;

    button {
      padding: 0.7rem 1.5rem;
      border-radius: 10px;
      border: none;
    }
  }
}

.connectedInstructor_container {
  border: 1px solid #d9d9d9;
}

.date-display {
  width: 100%;
  // max-width: 800px;
  margin: 0 auto;
}

.date-tiles-container {
  display: flex;
  width: auto;
  overflow-x: scroll;
  /* Allow the container to be as wide as its content */
}

.date-tile {
  // flex: 0 0 auto;
  padding: 1rem 0;
  margin-right: 1rem;
  background-color: lightgray;
  border-radius: 5px;
  width: 12rem;
}

.tile-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  text-align: center;
}

.bookSlot {
  // border: 2px solid #242a46;
  color: white;
  padding: 1rem;
  margin-top: 2rem;
  // border-radius: 20px;
  width: 100%;
}

.timeSlab {
  padding: 0.5rem 0;
  background-color: transparent;
  color: black !important;
  border: 1px black solid;
  border-radius: 10px;
}

.react-multi-carousel-track {
  li {
    width: max-content !important;
    background-color: white;
    margin-right: 1rem;
  }
}

.timeSlot {
  // width: max-content;
  color: black !important;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: auto !important;
  // margin-right: 10px;
  background-color: white;
}

.timeSlot:hover {
  background-color: #6fa5ef !important;
  color: white !important;
}

.searchBar {
  display: flex;
  align-items: center;
  // width: 95%;
  border: 1px #8990af solid;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 10px;

  input {
    width: 100%;
    margin: 0 1rem;
    border: none;
    font-size: large;
  }
}

.benefits_page {
  display: flex;
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  align-items: stretch;
  justify-content: center;
  text-align: center;

  @media screen and (max-width: 798px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.benefit_block {
  border: 1px #d9d9d9 solid;
  border-radius: 20px !important;
}

.connectedIntructor_list {
  h1 {
    @media screen and (max-width: 798px) {
      text-align: center;
    }
  }

  .noDataComponent {
    width: 60%;
    margin: 0 auto;

    @media screen and (max-width: 798px) {
      width: 100%;
    }
  }
}

.benefit_modal {
  width: 60%;
  position: absolute;
  top: 50%;
  padding: 1rem;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 20px;
  text-align: justify;
  max-height: 80%;

  @media screen and (max-width: 798px) {
    width: 80%;
    height: 70%;
    overflow-y: scroll;
    text-align: justify;
  }

  .benefits_iframe {
    max-width: 100%;
    max-height: 300px;
    overflow-y: hidden;

    @media screen and (max-width: 798px) {
      height: auto;
    }
  }
}

.benefits_iframe_image {
  max-width: 100%;
  max-height: 500px !important;
  overflow-y: hidden;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 798px) {
    height: auto;
  }
}

.benefits_iframe_image2 {
  max-width: 100%;
  max-height: 200px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  overflow-y: hidden;

  @media screen and (max-width: 798px) {
    height: auto;
  }
}

.displayFlexColumn {
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: center;
}

.instructorConnected {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;

  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 798px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .connected_block {
    border: 1px solid #d9d9d9;
    border-radius: 30px;
    height: max-content;
    padding: 20px;
  }

  button {
    margin-bottom: 0 !important;
    padding: 0.5rem !important;
  }

  .viewProfile {
    padding: 1rem;
    border: 1px solid #6fa5ef;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    margin-top: 1rem;
    color: #6fa5ef;

    &:hover {
      background-color: #6fa5ef;
      color: white;
    }
  }

  .pendingApproval {
    padding: 1rem;
    border: 1px solid red;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    margin-top: 1rem;
    color: red;
    cursor: no-drop;

    &:hover {
      background-color: red;
      color: white;
    }
  }
}

.buttonsCredit {
  justify-content: space-evenly;
  display: grid;
  text-align: center;
  gap: 5px;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  border-radius: 10px;
  margin: 1rem 0.5rem;

  @media screen and (max-width: 798px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  button {
    padding: 0.5rem !important;
  }

  .creditButton {
    background-color: transparent;
    border: 2px solid #d9d9d9;
    color: #242a46;
    // width: 20%;
    // margin: 0 .5rem;
    border-radius: 10px;
    position: relative;
  }

  .special::after {
    content: "";
    background-image: url("./../../assets/img/bookmark.png");
    /* Replace with the actual path to your image */
    background-size: cover;
    width: 25px;
    height: 25px;
    position: absolute;
    top: -3px;
    right: -3px;
  }

  .creditButton:hover {
    background-color: #242a46;
    border: 2px solid #242a46;
    color: white;
  }
}

.connected_block .row1 {
  display: flex;
  justify-content: space-between;
}

.creditButton2 {
  width: 50%;
  text-align: center;
  color: white;
  margin: 10px;
  border-radius: 20px;
  border: none;
  padding: 10px 20px;
}

.creditButton:hover {
  background-color: #242a46;
  color: white;
  // width: 20%;
  // margin: 0 .5rem;
  cursor: pointer;
}

.image-wrapper {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  margin: 2rem 0;
  border: 1px solid black;
  width: 200px;
  height: 200px;

  img {
    border-radius: 50%;
  }
}

.icon-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  padding: 5px;
  border-radius: 50%;
}

.icon {
  color: #000;
  font-size: 20px;
}

.today {
  background-color: #6fa5ef33 !important;
  border-left: 5px solid #6fa5ef !important;

  h2 {
    color: black !important;
  }
}

.modal-title {
  padding-bottom: 1rem;
}

.modalLoading {
  // background-color: red !important;
  border: none !important;
  background-color: transparent !important;
}

.timeSlabs {
  margin: 1rem 0;

  .swiper {
    height: 50px;

    .swiperSlide {
      width: max-content !important;
      margin-right: 1rem !important;
    }
  }
}

.css-15v22id-MuiAccordionDetails-root {
  background-color: white;
  padding: 0% !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  /* Apply your desired color */
  color: red !important;
  /* Change this to the color you want */
}

.css-67l5gl {
  box-shadow: none !important;
}

.timePickers {
  display: flex;
  // padding: 1rem 0;
  gap: 10px;
  align-items: center;

  input {
    background-color: white;
    color: black;
    border: 1px solid white;
    border-radius: 10px;
    width: 100%;
    padding: 0.5rem 10px;
    outline: none;

    @media screen and (max-width: 798px) {
      width: 100%;
    }
  }
}

.mySwiper {
  padding: 0.5rem 0 !important;
  width: 100% !important;

  .swiper-button-next,
  .swiper-button-prev {
    background-color: whitesmoke !important;
    color: black !important;
    /* margin-left: -0.5rem !important; */
    /* margin-top: -15px !important; */
    border-radius: 50% !important;
    width: 40px !important;
    height: 40px !important;
    top: var(--swiper-navigation-top-offset, 50%) !important;
  }

  .swiper-button-prev {
    left: var(--swiper-navigation-sides-offset, 0px) !important;
  }

  .swiper-button-next {
    right: var(--swiper-navigation-sides-offset, 0px) !important;
  }

  @media screen and (max-width: 798px) {
    padding: 0 !important;

    .swiper-button-next,
    .swiper-button-prev {
      width: 20px !important;
      height: 20px !important;
      top: var(--swiper-navigation-top-offset, 90%) !important;
      margin-right: -4% !important;
      margin-left: -3% !important;
    }
  }
}

.mySwiper2 {
  padding: 0 !important;

  .swiper-button-next,
  .swiper-button-prev {
    background-color: whitesmoke !important;
    color: black !important;
    /* margin-left: -0.5rem !important; */
    /* margin-top: -15px !important; */
    border-radius: 50% !important;
    width: 40px !important;
    height: 40px !important;
    top: var(--swiper-navigation-top-offset, 66%) !important;
  }
}

.mySwiper .ala button {
  padding: auto !important;
}

.swiper-button-next::after {
  content: ">" !important;
  font-size: 25px !important;

  @media screen and (max-width: 798px) {
    font-size: 15px !important;
  }
}

.swiper-button-prev::after {
  content: "<" !important;
  font-size: 25px !important;

  @media screen and (max-width: 798px) {
    font-size: 15px !important;
  }
}

.ant-select-dropdown {
  z-index: 9999999999999 !important;
}

.ant-select-selector {
  border: 1px solid #8990af !important;
}

.ant-space-item {
  width: 100% !important;
}

.carInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.pac-container {
  z-index: 1111111111 !important;
}

.row1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.row-middle {
  align-items: center;
}

.column {
  // flex-grow: 1;
  // flex-basis: 0;
  width: 100%;
  text-align: center;
}

.calendar {
  display: block;
  position: relative;
  width: 100%;
}

.calendar .header {
  font-weight: 700;
  font-size: 100%;
  padding: 0.5rem 0;
  align-items: center;
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: #8990af;
  font-size: 70%;
  padding: 0.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  // position: relative;
  height: 1.5rem;
  font-size: 70%;
  // overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; // text-align: center;
}

.calendar .body .selected {
  background-color: #6fa5ef !important;
  border-radius: 5px;
  color: white;
}

.calendar .body .today1 {
  background-color: #d9d9d9 !important;
  border-radius: 5px;
  color: white;
}

.past-date {
  color: #8990af !important;
}

.calendar .body .cell .number {
  font-weight: 800;
}

.classType {
  margin-top: 20px;

  .options {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    // flex-wrap: wrap;

    .option {
      display: flex;
      align-items: center;
      gap: 10px;

      input {
        height: 14px;
        width: 14px;
      }

      label {
        font-size: 14px;

        @media screen and (max-width: 798px) {
          font-size: 10px;
        }
      }
    }
  }
}

.modal_booklesson {
  position: absolute;
  top: 50%;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 15px;
  width: 25%;
  margin-top: 1rem;
  padding: 1rem;

  @media screen and (max-width: 798px) {
    height: 70%;
    overflow-y: scroll;
    width: 80%;
  }
}

.contactDetails {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: #d9d9d9 1px solid;
  border-radius: 10px;
  padding: 0.5rem 0;
}

.inputField_input {
  input {
    border: none;
    outline: none;
    color: black;
    border: 1px solid black;
    border-radius: 10px;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    padding: 12px 20px;
    background-color: white;
  }
}
